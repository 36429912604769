<script>
  import { createEventDispatcher } from 'svelte'
  import Food from './food.svelte'
  import { squareButton as Button, Arrow } from '../ui'
  import { weight, portions as sum } from '../../food/src/models/food'

  const dispatch = createEventDispatcher()

  export let top = 0 // offset for sticky elements
  export let group 
  const { name, foods, icon } = group
  $: dispatch('calculate', group)

  // Calculate total amount of portions and weight for the group.
  // It's NOT used to calcuate total values for all the foods.
  // This MAY be used to show it to the user.
  function calculate () {
    const portions = foods.map(food => food.portions)
    group.portions = sum(portions)
    group.weight = weight(foods, portions)
  }

  calculate()

  // Foods visibility logic
  export let showAll = false
  let display = Array(foods.length)
  const hasPortions = food => !!food.portions
  function expand() { display = display.fill(true) }
  function collapse() { display = foods.map(hasPortions) }
  function triggerShow () { showAll = !showAll }
  $: { showAll ? expand() : collapse() }

  /* function toggleHeight(e) {
    const el = e.target.nextElementSibling
    el.style.height = (el.scrollHeight - el.offsetHeight) + 'px'
  } */
</script>

<style>
  .wrapper {
    display: flex;
    align-items: center;
  }
  .header {
    position: sticky;
    top: 0;
    background-color: white; /* Window; */
    z-index: 1;
  }
  h1 {
    font-size: inherit;
    line-height: 2em;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%;
  }
  li {
    padding: 0.618em 0;
    padding-left: 0.8em;
    border-top: solid 1px lightgray;
  }
  .push {
    margin-left: auto; /* push right in a flexbox */
  }
  .crop {
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icon {
    min-width: 2em; /* prevent shirinking in flex */
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    overflow: hidden;
  }
</style>

<div class="wrapper header" { top } style="top:{top}px;">
  <span class='icon'>{ @html icon || '' }</span>
  <h1 class="crop">{ name }</h1>
  <span class="push"></span>
  <Button on:click={ triggerShow }><Arrow angle={ showAll ? 180 : 0 }/></Button>
 </div>

<ul>
  {#each foods as food, i}
    <li style="display:{ display[i] ? 'block' : 'none' }">
      <Food { food } on:calculate={ calculate } on:click/>
    </li>
  {/each}
</ul>
