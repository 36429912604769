<script>
  export let group
  import Nutrient from './nutrient.svelte'
  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)
</script>

<style>
  h1 {
    font-size: larger;
    margin-bottom: 0;
  }
  td {
    vertical-align: bottom;
    padding-bottom: 0.5em;
  }
  .min, .max, .fact {
    text-align: right;
    font-weight: bold;
  }
 </style>

<tr>
  <td><h1>{ group.name }</h1></td>
  <td class="min">{ t`min` }</td>
  <td class="fact">{ t`fact` }</td>
  <td class="max">{ t`max` }</td>
</tr>
{ #each group.nutrients as nutrient }
  <Nutrient { nutrient }/>
{ /each }
