<script context='module'>
  // Array of functions shared among the instances of this component
  const handlers = []
  let selectedNutrient = undefined
</script>

<script>
  import Foods from './foods.svelte'
  // import { createEventDispatcher } from 'svelte'
  import { onMount } from 'svelte'

  let showFoods
  const handle = state => showFoods = state
  onMount(() =>  handlers.push(handle))

  export let nutrient
  const { rni, ul } = nutrient.norm || {}

  function getStatus (amount) {
    if (amount !== undefined) {
      if (amount > ul) { return  'high' }
      if (amount < rni) { return 'low' }
    }
    return ''
  }
  $: status = getStatus(nutrient.amount)

  // Round to some numbers after decimal point, if necessary
  const toFixed = n => n &&
    ( n < 10
      ? n.toFixed(2)
      : n.toFixed()
    )
  const join = (...props) => props.join(', ')

  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)

  // Returns width of the color bar
  // It uses the sigma function: y = x / (x + 1)
  // For this function: 
  // y == 8 for x == 4, i.e. width == 80%
  // y == 2 for x == 1, i.e. width == 10%
  // Thus we scale the input such that UL goes to 80%, and RNI goes to 10%
  function getWidth(amount) {
    let width = 0
    if (amount) {
      const k = ul ? 4 / ul : rni ? 1 / rni : (10 / amount)
      const x = k * amount
      width = 100 * x / (x + 1)
      if (width > 100) { width = 100 }
    }
    return `${ width }%`
  }
  $: barWidth = getWidth(nutrient.amount)

  let opened // Was opened or closed 
  const clickOnRow = () => {
    if (selectedNutrient === nutrient) {
      selectedNutrient = undefined
      handlers.forEach(f => f(false))
    } else {
      opened = selectedNutrient !== undefined
      selectedNutrient = nutrient
      handlers.forEach(f => f(f === handle))
    }
  }
</script>

<style>
  tr {
    line-height: 2rem;
    cursor: pointer;
  }
  tr.selected {
    background-color: rgba(255, 255, 255, 0.7);
  }
  td {
    border: solid 1px lightgray;
    border-left: 0;
    border-right: 0;
  }
  td:first-child {
    /* Shrink the content */
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td:not(:first-child) {
    padding-left: 0.3em;
  }
  .name:first-letter {
    text-transform: capitalize;
  }
  .min, .max, .amount {
    text-align: right;
    min-width: 5ch;
  }
  .amount.low {
    /*color: #e27832;*/
    color: #d50000;
  }
  .amount.high {
    background-color: #ee8f8f;
  }
  .amount, .amount > span {
    /* To make the .bar with absolute position work */
    position: relative;
  }
  /* Bar colors are from https://digitalsynopsis.com/design/color-schemes-palettes/
     Scheme 24 */
  .bar {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #b0d8a4;
  }
  .bar.low {
    background-color: #fee191;
  }
  .bar.high {
    background-color: #fd8060;
    background-color: #e84258;
    background-color: #ff877d;
  }
</style>

<tr on:click={ clickOnRow } class={ showFoods ? 'selected': '' }>
  <td class="name">{ nutrient.name }, { t(nutrient.unit) }</td>
  <td class="min">{ rni || '' }</td>
  <td class="amount">
    <div class="bar { status }" style="width: { barWidth }"></div>
    <span> { nutrient.amount == undefined ? '' : toFixed(nutrient.amount) }</span>
  </td>
  <td class="max">{ ul || '' }</td>
</tr>

{ #if showFoods }
  <tr>
    <td colspan=4>
      <Foods { opened } { nutrient }/>
    </td>
  </tr>
{ /if }
