<script>
  import { createEventDispatcher } from 'svelte'
  import { onMount } from 'svelte'
  import { squareButton as Button, Arrow } from '../ui'
  import Group from './group.svelte'
  import { weight, nutrientsPerPortion } from '../../food/src/models/food'

  const dispatch = createEventDispatcher()

  export let data
  const { foods, foodsGroups, facts } = data
  let totalFoods // Amount of foods whith at least one portion
  let totalWeight

  const byName = (a, b) => a.name.localeCompare(b.name)
  // Replace foods' ids with the food objects in the groups
  function replaceFoods (group) {
    group.foods = foods
      .filter(food => group.foods.includes(food.id))
      .sort(byName)
  }

  foodsGroups.forEach(replaceFoods)
  
  // Calculate total amount of portions and total weight of the foods
  function calculate () {
    const portions = foods.map(food => food.portions)
    totalFoods = foods.filter(food => food.portions > 0).length
    totalWeight = weight(foods, portions)

    dispatch('calculate')
  }

  calculate()

  function onFood (e) {
    const food = e.detail
    const inPortion = nutrientsPerPortion(food, food.nutrients)
    dispatch('click', inPortion)
  }

  // Foods visibility
  let display = Array(foodsGroups.length)
  function showMore () { display = display.fill(true) }
  function showLess () { display = display.fill(false) }
  
  // We calculate top offset for the sticky children
  let top
  onMount(() => {
    const el =  document.getElementById('uV8VNr')
    top = el && el.offsetHeight
  })

  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)
</script>

<style>
  .wrapper {
    display: flex;
    align-items: center;
  }
  .header {
    position: sticky;
    top: 0;
    background-color: white; /* Window */;
    z-index: 2;
  }
  .push {
    margin-left: auto; /* push right in a flexbox */
  }
  .note {
    font-size: smaller;
    font-weight: normal;
    line-height: normal;
    color: gray;
  }
  h1 {
    font-size: inherit;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  li {
    overflow: visible; /* for positin: sticky of children */
  }
  li:not(:last-child) {
    border-bottom: solid 1px lightgray;
  }
</style>

<div class='wrapper header' id='uV8VNr'>
  <h1>
    { #if totalWeight } { t`totalWeight` }: { totalWeight } { t`g` } { /if }&zwnj;
      <br>
    <span class='note'>
      { #if totalFoods } { t`totalFoods` }: { totalFoods } { /if }&zwnj;
    </span>
  </h1>
  <span class='push'></span>
  <Button on:click={ showMore }><Arrow/></Button>
  <Button on:click={ showLess }><Arrow angle={ 180 }/></Button>
</div>

<ul>
  { #each foodsGroups as group, i }
    <li>
      <Group { group } { top } showAll={ display[i] } on:calculate={ calculate } on:click={ onFood }/>
    </li>
  { /each }
</ul>
