<script>
  import { onMount } from 'svelte'
  import stored from './store.js'
  import { 
    nutrientsPerPortion
  } from '../../food/src/models/food'

  export let nutrient
  export let opened // True if another component was opened before

  let open

  onMount(() => {
    if (opened) {
      open = true
    } else {
      // Timeout allows Svelte to render the component first before transitions 
      setTimeout(() => { open = true })
    }
  })

  const { foods } = stored()
  const getAmount = nutrientsPerPortion([nutrient])

  const sorted = foods
    .map(food => ({
      food,
      amount: getAmount(food, food.nutrients)[0]
    }))
    .filter(({ amount }) => amount !== undefined)
    .sort((a, b) => b.amount - a.amount)

  // Round to some numbers after decimal point, if necessary
  const toFixed = n => n &&
    ( n < 10
      ? n < 1 ? n.toFixed(2) : n.toFixed(1)
      : n.toFixed()
    )

  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)

</script>

<style>
  .noscroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .noscroller::-webkit-scrollbar {
    display: none;
  }
  div {
    background-color: white;
    height: 5vh;
    overflow-y: scroll;
    padding: 0 1rem;
    cursor: default;
  }
  div.open {
    height: 30vh;
  }
  div.roll {
    transition: height 0.3s linear;
  }
  p {
    position: sticky;
    top: 0;
    margin: 0;
    padding-bottom: 0.618rem;
    background-color: inherit;
    font-weight: bold;
  }
  table {
    width: 100%;
    cursor: initial;
  }
  .name {
    /* Shrink the content */
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* Equal to the values of the table #nutrients */
  .amount {
    width: 7ch;
    text-align: right;
  }
</style>

<div class="{ open ? 'open' : '' } { opened ? '' : 'roll' }">
  { #if sorted.length }
    <p>{ t`amountInOnePortion` }, { t(nutrient.unit) }</p>
    <table>
    { #each sorted as data }
      <tr>
        <td class='name'>{ data.food.name }</td>
        <td class='amount'>{ toFixed(data.amount) }</td>
      </tr>
    { /each }
    </table>
  { :else }
    <p>{ t`noFoodsFound` }</p>
  { /if}
</div>
