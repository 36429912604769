// Returns hashmap of nutrients in the food, added to initial ammount
// Amount of nutrients is added to initial amount
const inFood = ({ portion }, facts, portions, initial) =>
  Object.keys(facts).reduce((map, id) => {
    const amount = facts[id]
    map[id] = (map[id] || 0) + portions * portion.grams * amount / 100
    return map
  }, initial)

// Returns nutrients in the foods in the portions.
// For one food or array of foods:
//    (Object, Object, Number)
//    ([Objects], [Objects], [Numbers])
module.exports = (foods, facts, portions) => {
  // Convert types and set defaults
  if (!(foods instanceof Array)) {
    foods = [foods]
    facts = [facts]
    // For one food we assume one portion
    portions = portions === undefined ? [1] : [portions]
  } else {
    // It's ok to have no portions defined, so we do it here
    if (!portions) { portions = [] }
  }

  return foods.reduce((map, food, i) => {
    if (facts[i] && portions[i]) {
      inFood(food, facts[i], portions[i], map)
    }
    return map
  }, {})
}
