<style>
  button {
    box-sizing: content-box; /* otherwise the text is badly aligned */
    /* display: inline-block; */
    vertical-align: text-bottom;
    /* border: solid 1px ButtonShadow; */
    border: none;
    border-radius: .2em;
    border-width: 1px;
    min-width: 1.618em;
    height: 1.618em;
    padding: .5em; /* change default button padding */
    /* Text appearence */
    /* text-align: center; */
    /* margin: auto; */
    /* Appearence */
    /* color: ButtonText; */
    /* background-color: ButtonFace; */
    background-color: inherit;
    color: inherit;
  }
  button:hover {
    background-color: ButtonFace;
  }
  button:hover:disabled {
    background-color: inherit;
  }
  button:disabled {
    opacity: 0;
  }
</style>

<button on:click style={ $$props.style } disabled={ $$props.disabled }><slot></slot></button>
