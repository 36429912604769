<script>
  import Group from './group.svelte'
  import store from './store.js'

  export let data
  const { nutrients } = data
  let groups = data.nutrientsGroups // Initialization
  $: groups = data.nutrientsGroups // When data updated

  store(data)

  // Replace nutients' id with nutrient objects for the given group
  function replaceNutrients (group) {
    group.nutrients = nutrients
      .filter(nutrient => group.nutrients.includes(nutrient.id))
  }

  groups.forEach(replaceNutrients)
</script>

<style>
  table {
    width: 100%;
    border-collapse: collapse;
  }
</style>

<table>
  {#each groups as group}
    <Group { group }/>
  {/each} 
</table>
