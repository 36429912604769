// Helpers
const prop = name => obj => obj && obj[name]

// Given amount per 100 grams returns amount per given grams
const calc = grams => amount => amount && (amount * grams / 100)

// Returns array [Number] of nutrients in one portion of the food
module.exports = nutrients => {
  nutrients = nutrients.map(prop('id'))
  return (food, nutrientsInFood) =>
    nutrients
      .map(id => nutrientsInFood[id])
      .map(calc(food.portion.grams))
}
