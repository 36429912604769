<script>
  import { createEventDispatcher } from 'svelte'
  import { weight } from '../../food/src/models/food'
  import { squareButton as Button, Plus, Minus } from '../ui'

  const dispatch = createEventDispatcher()

  export let food
  food.portions |= 0

  const more = () => food.portions += 1
  const less = () => (food.portions > 0) && (food.portions -= 1)
  $: noPortions = food.portions < 1
  $: numerousPortions = food.portions > 1

  //$: food.weight = food.portion.grams * food.portions
  $: food.weight = weight([food], [food.portions])
  // We use food in the event just to trigger it whenever food changes
  $: dispatch('calculate', food)

  function click (e) { dispatch('click', food) }

  const url = id => `https://fdc.nal.usda.gov/fdc-app.html#/food-details/${id}/nutrients`

  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)
</script>

<style>
  .wrapper {
    display: flex;
    align-items: center;
  }
  .info {
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
 .crop {
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  a {
    color: lightgray;
  }
  .note {
    font-size: smaller;
    line-height: normal;
    color: gray;
  }
  .push {
    margin-left: auto; /* push right in a flexbox */
  }
  .portions {
    display: inline-block;
    text-align: center;
    line-height: 2em;
    min-width: 2em;
    height: 2em;
    font-size: larger;
  }
  button {
    margin: 0;
    padding: 0;
    border: none;
    min-width: 1.15em;
  }
  button:hover {
    border: solid 1px gray;
  }
</style>

<div class="wrapper">
  <div class="info">
    <span class="crop">{ food.name }</span>
    <div class="note">
      <!-- button on:click={ click }>i</button>
      <a target="_blank" href="{ url(food.source.usda) }">u</a-->
      { t`perPortion` } { food.portion.grams }
      { t`g` }{ #if numerousPortions }, { t`inTotal` } { food.weight }
      { t`g` }{ /if }
    </div>
  </div>
  <span class="push"></span>
  <span class="portions">{ food.portions || '' }</span>
  <Button on:click={ less } disabled={ noPortions }><Minus/></Button>
  <Button on:click={ more }><Plus/></Button>
</div>
