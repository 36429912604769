<script>
  import Foods from '../../components/foods'
  import Nutrients from '../../components/nutrients'
  import { totalNutrients } from '../../food/src/models/food'
  import ls from 'local-storage'

  let data
  ;(async () => {
    const res = await fetch('/api/food/')
    data = await res.json()
    load(data)
    updateNutrients()
  })()

  // Loads data from the browser's local storage
  function load({ foods }) {
    try {
      const portions = ls('portions')
      if (portions) {
        foods.forEach(food => food.portions = portions[food.id])
      }
    } catch (er) {
      console.error(er)
    }
  }

  // Saves data to the browser's local storage
  function save({ foods }) {
    const portions = foods.reduce((obj, food) => {
      if (food.portions) { obj[food.id] = food.portions }
      return obj
    }, {})
    ls('portions', portions)
  }

  // Updates amount of nutrients
  function updateNutrients () {
    const { foods } = data 
    const portions = foods.map(food => food.portions)
    const facts = foods.map(food => food.nutrients)
    const values = totalNutrients(foods, facts, portions)
    const setAmount = nutrient => { nutrient.amount = values[nutrient.id] }
    data.nutrients.forEach(setAmount)
    data = data
  }

  function onFoodClick (e) {
    const values = e.detail || {}
    updateNutrients(values)
  }

  // Invoked when amount of portions of any food changes
  function calculate () {
    updateNutrients()
    save(data)
  }

  // Localizaton
  const phrases = window[Symbol.for('phrases')]
  const dictionary = window[Symbol.for('dictionary')]
  const t = dictionary(phrases)
</script>

<style>
  /*
  .noscroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .noscroller::-webkit-scrollbar {
    display: none;
  }
  */
  .container {
    box-sizing: border-box;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    padding: 0 1rem;
  }
  .container.nutrients {
    background-color: rgba(52, 52, 52, 0.03);
    font-size: smaller;
  }
  .container.nutrients > .content {
    margin-bottom: 2rem;
  }
  /* The width of these elements must be used in responsive dynamics */
  .container.nutrients {
    max-width: 28rem;
  }
  .container.foods > .content {
    max-width: 26rem;
  }
  .slides {
    position: absolute;
    top: 3rem; /* equal to height(#nav) */
    bottom: 0;
    /* Fit the parent container */
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overflow-x: hidden;
  }
  .slide {
    flex-shrink: 0;
    scroll-snap-align: start;
    width: 100%;
  }
  nav {
    /* height: 8%; /* 100% - height(.slides) */
    height: 3rem;
    display: flex;
  }
  nav > a {
    flex-grow: 1;
    color: inherit;
    text-decoration: none;
    /* For the text inside <a>: */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  nav > a.nutrients {
    background-color: rgba(52, 52, 52, 0.03);
  }
  @media only screen and (min-width: 46em) {
    /* Desktop */
    nav {
      display: none;
    }
    .container {
      padding: 0 2rem;
    }
    .container.nutrients {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .container.nutrients::-webkit-scrollbar {
      display: none;
    }
    .slides {
      top: 0;
    }
    .slide {
      flex-grow: 1;
      width: auto;
    }
  }
</style>

<nav>
  <a href='#nutrients' class='nutrients'>{ t`nutrients` }</a>
  <a href='#foods'>{ t`foods` }</a>
</nav>
<div class='slides'>
  <div id='nutrients' class='container nutrients slide'>
    <div class='content'>
      { #if data }
        <Nutrients { data }/>
      { /if }
    </div>
  </div>
  <div id='foods' class='container foods slide'>
    <div class='content'>
      { #if data }
        <Foods { data } on:click={ onFoodClick } on:calculate={ calculate }/>
      { /if }
    </div>
  </div>
</div>
