const nutrientsPerPortion = require('./nutrientsPerPortion')
const weight = require('./weight')
const portions = require('./portions')
const totalNutrients = require('./totalNutrients')

module.exports = {
  nutrientsPerPortion,
  weight,
  portions,
  totalNutrients
}
