import { dictionary } from 'ization'
import App from './App.svelte'

window[Symbol.for('dictionary')] = dictionary

const app = new App({
	target: document.querySelector('#food')
})

export default app
